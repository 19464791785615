@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #e9edf1;
  --secondary: #b7c3d1;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 1px solid var(--primary);
}

/* Table specific styles */
.table-header {
  @apply bg-gray-200 text-gray-600 uppercase text-sm leading-normal;
}

.table-row {
  @apply bg-white hover:bg-gray-50 border-b border-gray-200 cursor-pointer;
}

.table-cell {
  @apply py-3 px-6;
}

.table-cell-centered {
  @apply text-center;
}

.table-cell-right {
  @apply text-right;
}

.text-red {
  @apply text-red-500;
}

.text-green {
  @apply text-green-500;
}

/* Spacing adjustments */
.custom-padding {
  @apply p-4;
}

.custom-margin {
  @apply my-2;
}

/* Miscellaneous styles */
.hidden_link {
  display: none;
}

.error_container {
  @apply block mx-auto font-sans w-[800px];
}

.error_heading, .error_section {
  @apply text-center my-8;
}

.error_heading h3 {
  @apply text-gray-500;
}

.error_container img {
  @apply w-1/2 mx-auto mb-8;
}

.error_container a {
  @apply text-blue-500 underline;
}

.error_container h1 {
  @apply text-4xl mb-1;
}

.custom-scrollbar::-webkit-scrollbar {
    /* width: 1px;
    background: rgba(255, 255, 255);   */
	display: none;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    /* background: rgba(82, 144, 244);
    border-radius: 10px;   */
	display: none;
}

/* For Firefox */
.custom-scrollbar {
    scrollbar-width: none;
    /* scrollbar-color: rgba(82, 144, 244) rgba(255, 255, 255); */
}

/* To position scrollbar on the left */
.custom-scrollbar {
    direction: rtl;
}

.custom-scrollbar > * {
    direction: ltr;
}

@media only screen and (max-width: 800px) {
  .error_container h1 {
    @apply text-2xl;
  }

  .error_container h3 {
    @apply text-base;
  }

  .error_heading, .error_section {
    @apply text-center my-2;
  }

  .error_container {
    @apply w-[400px];
  }
}
